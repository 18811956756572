<template>
  <form @submit.prevent="logReturnCode" class="relative">
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm"
        >Distributor
        <span class="text-red-500">*</span>
      </label>
      <div
        class="fixed inset-0 overflow-hidden z-10"
        v-if="contactSelectOpen"
        @click="contactSelectOpen = false"
      ></div>
      <div class="relative mx-3">
        <div class="relative -mx-3">
          <input
            readonly
            @click="openSearchContact"
            v-model="contactName"
            class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
            type="text"
          />
          <button
            v-if="contactName"
            name="Clear Supplier"
            @click="clearContact"
            class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div
          v-if="contactSelectOpen"
          class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
        >
          <div class="relative flex flex-col">
            <input
              ref="tbSupplierSearch"
              type="text"
              v-model="contactSearchTerm"
              @keydown="searchContact"
              class="bge-input bge-input-spacing rounded"
              placeholder="Contact name (enter 3 letters or more to search)"
            />
            <div class="flex flex-col overflow-y-scroll bg-white mt-2">
              <template v-for="result in contactResults">
                <button
                  type="button"
                  :key="result.contact_id"
                  @click="selectContact(result)"
                  class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                >
                  <span class="ml-1">{{ result.name }}</span>
                </button>
              </template>
              <p class="mx-auto py-4 px-8" v-if="contactResults == ''">
                No contacts match your search
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Engineer Name</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="codeLog.metadata.engineer_name"
        type="text"
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Customer Name</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="codeLog.metadata.customer_name"
        type="text"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">
        Code Level
        <span class="text-red-500">*</span>
      </label>
      <div class="w-full relative">
        <select
          class="bge-input bge-select rounded"
          v-model="codeLog.code_level"
        >
          <option>LEVEL 1</option>
          <option>LEVEL 2</option>
          <option>LEVEL 3</option>
          <option>LEVEL 4</option>
          <option>LEVEL 5</option>
          <option>LEVEL 6</option>
          <option>CALCODE</option>
          <option>MANPRO (AGS/OPA/RBT)</option>
          <option>MANPRO (DATABASE)</option>
          <option>IVY SERVICE</option>
          <option>IVY RESERVED</option>
          <option>IVY CALIBRATION</option>
          <option>IVY CHARGEABLE</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Challenge</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="codeLog.challenge"
        type="text"
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Return Code</label>
      <input
        class="w-full bge-input bge-input-spacing rounded"
        v-model="codeLog.response"
        type="text"
      />
    </div>
    <Toggle
      class="mt-4"
      label="Is Chargable"
      :checked="codeLog.is_chargeable"
      labelPosition="left"
      labelClasses="uppercase font-semibold text-sm"
    />
    <div class="relative flex justify-end mt-5">
      <div
        v-show="!canSubmitForm"
        class="opacity-50 bg-white absolute inset-0"
      ></div>
      <button
        :disabled="isBusy || !canSubmitForm"
        type="submit"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </div>
  </form>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");

export default {
  name: "QuickLogReturnCode",
  components: {
    Spinner,
    Toggle,
  },
  data() {
    return {
      isBusy: false,
      codeLog: {
        is_chargeable: false,
        metadata: {},
      },
      contactName: "",
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
    };
  },
  computed: {
    canSubmitForm() {
      return this.codeLog.agent_id && this.codeLog.code_level;
    },
  },
  methods: {
    openSearchContact: function() {
      this.contactSearchTerm = "";
      this.contactResults = [];
      this.contactSelectOpen = true;
      this.$nextTick(function() {
        this.$refs.tbSupplierSearch.focus();
      });
    },
    searchContact: _.debounce(async function() {
      this.contactResults = [];
      if (this.contactSearchTerm != "" && this.contactSearchTerm.length >= 3) {
        let results = await this.ContactService.searchContact(this.contactSearchTerm);
        this.contactResults = results.data;
      }
    }, 500),
    selectContact: function(contact) {
      this.codeLog.agent_id = contact.contact_id;
      this.contactName = contact.name;
      this.contactSelectOpen = false;
    },
    clearContact: function() {
      this.codeLog.agent_id = "";
      this.contactName = "";
      this.$forceUpdate();
    },
    logReturnCode: async function() {
      this.isBusy = true;
      try {
        let result = await this.ReturnCodeService.logReturnCode(this.codeLog);
        this.isBusy = false;
        this.$emit("complete");
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Success",
                  close: onClose,
                },
              },
              "Logged return code successfully."
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (error) {
        console.error(error);
        this.isBusy = false;
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There's been an error whilst logging this return code."
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
  },
};
</script>
